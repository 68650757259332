import React from "react"
import Helmet from "react-helmet";

import { graphql } from "gatsby"

import DefaultPage from "../templates/default-page";
import Footer from "../components/footer";
import Media from "../components/media";

class IndexPage extends React.Component<IndexPageQuery, {}> {
    constructor(props: any) {
        super(props)
    }

    state = {
        isShowLetterbox: false,
        letterboxItem: [] as any,
    }

    showPhoto(item: any) {
        this.setState({ isShowLetterbox: true, letterboxItem: item })
    }

    onLetterboxClose() {
        this.setState({ isShowLetterbox: false, letterboxItem: null })
    }

    render() {
        const MediaItems = this.props.data.media.edges
        .filter(p => !p.node.frontmatter.draft)
        .slice(0, 6)

        const copy = this.props.data.homepage.edges[0].node.frontmatter
    
        return (
            <DefaultPage isIndex={false} isShowLetterbox={this.state.isShowLetterbox} onLetterboxClose={() => this.onLetterboxClose()} letterboxItem={this.state.letterboxItem}>
                <Helmet>
                    <title>Frostfall - Media Archive</title>
                    <meta name="description" content="Frostfall - Media Archive" />
                </Helmet>

                <Media sectionHeader={copy.mediaHeading} archiveButtonText={copy.mediaArchiveButtonText} mediaItems={MediaItems} onPhotoClicked={(item) => this.showPhoto(item)} isArchive={true} />
                <Footer />
            </DefaultPage>
        )
    }
}

export default IndexPage;

export const pageQuery = graphql`
  query {
    homepage: allMarkdownRemark(filter: {fields:{sourceName: {eq: "homepage"}}}) {
      edges {
        node {
          frontmatter {
            ctaHeading
            ctaSubtitle
            ctaButtonText
            newsHeading
            newsArchiveButtonText
            mediaHeading
            mediaArchiveButtonText
            socialHeading
            socialSubtitle
            downloadHeading
          }
        }
      }
    },
    media: allMarkdownRemark(filter: {fields:{sourceName: {eq: "media"}}}, sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          fields {
            sourceName
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MM | DD | YYYY")
            path
            draft
            title
            image
          }
        }
      }
    }
  }
`