import React from "react"
import Image from './image/cmsImage'
import SmallImage from './image/cmsImageSmall'

interface IMediaProps {
    mediaItems: Array<GraphEdge<MediaPost>>
    isArchive?: boolean,
    onPhotoClicked: (param: any) => void
    sectionHeader: string
    archiveButtonText: string
}

class Media extends React.Component<IMediaProps>{
    constructor(props: any) {
        super(props)
    }

    onPhotoClicked(item: GraphEdge<MediaPost>) {
        this.props.onPhotoClicked(
            [
                <Image key="image" src={item.node.frontmatter.image} alt={item.node.frontmatter.altText} />,
                <h2 key="heading">{item.node.frontmatter.altText}</h2>,
                <div key="logo" className="o-logo">Frostfall</div>
            ]
        )
    }

    render() {
        const title = this.props.isArchive ? this.props.sectionHeader + " Archive" : this.props.sectionHeader
        const renderings = this.props.mediaItems.map(item => 
            <li className="o-photo-grid__photo-container" key={item.node.id}>
                <div onClick={() => this.onPhotoClicked(item)}>
                    <SmallImage className="o-photo-grid__photo" 
                        src={item.node.frontmatter.image}
                        alt={item.node.frontmatter.altText}
                    />
                </div>
            </li>
        )

        return (
            <section className="c-media o-section" id="media">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-7 offset-lg-1">
                            <h1 className="o-heading">
                                {/* <span>{title}</span> */}
                            </h1>
                        </div>
                        {/* {this.props.isArchive ? null : <div className="c-media__desktop-archive col-lg-4"><a className="o-button--archive" href="/mediaArchive">{this.props.archiveButtonText}</a></div>} */}
                    </div>
                </div>
                <ul className="o-photo-grid">
                    {renderings}
                </ul>
                {/* {this.props.isArchive ? null : <div className="c-media__mobile-archive"><a className="o-button--archive" href="/mediaArchive">{this.props.archiveButtonText}</a></div>} */}
            </section>
        )
    }
}

export default Media